'use client'

import * as React from 'react'
import { Provider } from 'react-redux'
import { setVersion } from '@/lib/features/version/versionSlice'
import { setCurrentUser } from '@/lib/features/currentUser/currentUserSlice'
import { setStyles } from '@/lib/features/styles/stylesSlice'
import { setGenders } from '@/lib/features/genders/gendersSlice'
import { setMembershipPeriods } from '@/lib/features/membershipPeriods/membershipPeriodsSlice'
import { setMembershipTypes } from '@/lib/features/membershipTypes/membershipTypesSlice'
import { setPurchaseTypes } from '@/lib/features/purchaseTypes/purchaseTypesSlice'
import { setVerifyTypes } from '@/lib/features/verifyTypes/verifyTypesSlice'
import { setPrices } from '@/lib/features/prices/pricesSlice'
import { makeStore, type AppStore } from '@/lib/store'
import { setAuthProviders } from '@/lib/features/authProviders/authProvidersSlice'
import { setCoupons } from '@/lib/features/coupons/couponsSlice'
import { setToken } from '@/lib/features/token/tokenSlice'
import { setIsUserMenuOpen, setIsMobileUserMenuOpen } from '@/lib/features/userMenu/userMenuSlice'
import type {
  AuthProvider,
  Coupon,
  Gender,
  MembershipPeriod,
  MembershipType,
  Price,
  PurchaseType,
  Style,
  User,
  VerifyType,
} from '@/types/entities'

interface Props {
  children: React.ReactNode
  currentUser: User | null
  styles: Style[] | null
  coupons: Coupon[] | null
  genders: Gender[] | null
  membershipPeriods: MembershipPeriod[] | null
  membershipTypes: MembershipType[] | null
  authProviders: AuthProvider[] | null
  purchaseTypes: PurchaseType[] | null
  verifyTypes: VerifyType[] | null
  prices: Price[] | null
  version: string
  token: string | null
}

export default (({
  children,
  currentUser,
  styles,
  coupons,
  genders,
  membershipPeriods,
  membershipTypes,
  authProviders,
  purchaseTypes,
  verifyTypes,
  prices,
  version,
  token,
}): React.ReactElement => {
  const storeRef = React.useRef<AppStore | null>(null)

  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore()

    // Set initial state from props (provided by server-side)
    storeRef.current.dispatch(setCurrentUser(currentUser ?? null))
    storeRef.current.dispatch(setStyles(styles ?? null))
    storeRef.current.dispatch(setCoupons(coupons ?? null))
    storeRef.current.dispatch(setGenders(genders ?? null))
    storeRef.current.dispatch(setMembershipPeriods(membershipPeriods ?? null))
    storeRef.current.dispatch(setMembershipTypes(membershipTypes ?? null))
    storeRef.current.dispatch(setAuthProviders(authProviders ?? null))
    storeRef.current.dispatch(setPurchaseTypes(purchaseTypes ?? null))
    storeRef.current.dispatch(setVerifyTypes(verifyTypes ?? null))
    storeRef.current.dispatch(setPrices(prices ?? null))
    storeRef.current.dispatch(setVersion(version ?? null))
    storeRef.current.dispatch(setToken(token ?? null))
    storeRef.current.dispatch(setIsUserMenuOpen(false))
    storeRef.current.dispatch(setIsMobileUserMenuOpen(false))
  }

  return <Provider store={storeRef.current}>{children}</Provider>
}) satisfies React.FC<Props>
