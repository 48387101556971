'use client'

import * as React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import StoreProvider from './StoreProvider'
import type {
  AuthProvider,
  Coupon,
  Gender,
  MembershipPeriod,
  MembershipType,
  Price,
  PurchaseType,
  Style,
  User,
  VerifyType,
} from '@/types/entities'

interface Props {
  children: React.ReactNode
  currentUser: User | null
  styles: Style[] | null
  coupons: Coupon[] | null
  genders: Gender[] | null
  membershipPeriods: MembershipPeriod[] | null
  membershipTypes: MembershipType[] | null
  authProviders: AuthProvider[] | null
  purchaseTypes: PurchaseType[] | null
  verifyTypes: VerifyType[] | null
  prices: Price[] | null
  version: string
  token: string | null
}

const queryClient = new QueryClient()

export default (({
  children,
  currentUser,
  styles,
  coupons,
  genders,
  membershipPeriods,
  membershipTypes,
  authProviders,
  purchaseTypes,
  verifyTypes,
  prices,
  version,
  token,
}): React.ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider
        currentUser={currentUser}
        styles={styles}
        coupons={coupons}
        genders={genders}
        membershipPeriods={membershipTypes}
        membershipTypes={membershipPeriods}
        authProviders={authProviders}
        purchaseTypes={purchaseTypes}
        verifyTypes={verifyTypes}
        prices={prices}
        version={version}
        token={token}
      >
        {children}
      </StoreProvider>
    </QueryClientProvider>
  )
}) satisfies React.FC<Props>
